.borda-cards-noticias{
    box-shadow: rgb(163, 163, 163)  3px 3px 15px  !important;
    background-color: white !important;
}

.titulo-noticia{
    font-size: 100pt !important;
}

.cor-botao{
    background-color: rgb(0, 167, 224);
}

.padding-botao{
    padding-right: 10%;
}

.cor-botao:hover{
    background-color: rgb(0, 154, 206) !important;
}
.div-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
  
.spinner {
    width: 50px;
    height: 50px;
}