.principal{
    height: 100vh;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.fundo-preto{
    background-color: rgb(0, 0, 0) !important;
}
.remover{
    height: 500px !important;
}