@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");

.brand-font {
  font-family: "Bangers", cursive;
  font-size: 50px !important;
}

.bg-dark {
  background-color: rgb(0, 0, 0) !important;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: rgb(0, 167, 224) !important;
}
