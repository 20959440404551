.rodape-fundo-preto{
    background-color: black !important;
}

.titulo-rodape{
    font-size: 22pt !important;
}

.cor-link{
    color: #ffffff !important;
}

.nav-link:hover {
    color: rgb(0, 167, 224) !important;
  }
  
.rodape-posicao {
    position: bottom;
}