.title-about-us {
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

.text-about-us {
    color: oldlace;
    font-weight: 400;
    text-shadow: 2px 2px 3px #000000;
    position: relative;
}