@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
.brand-font {
  font-family: "Bangers", cursive;
  font-size: 50px !important;
}

.bg-dark {
  background-color: rgb(0, 0, 0) !important;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: rgb(0, 167, 224) !important;
}

.rodape-fundo-preto{
    background-color: black !important;
}

.titulo-rodape{
    font-size: 22pt !important;
}

.cor-link{
    color: #ffffff !important;
}

.nav-link:hover {
    color: rgb(0, 167, 224) !important;
  }
  
.rodape-posicao {
    position: bottom;
}
.root {
    width: 100%;
    height: 100%;
  }
  
  .mockProgressBar {
    height: 2px;
  }
  
  .table {
    width: 100%;
    position: relative;
    table-layout: fixed;
  }
  
  .tableLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 999;
  }
  
  .divButton {
    margin-bottom: 20px;
  }
  
  .wrapper {
    position: relative;
  }
  
  .buttonProgress {
    color: blue;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12;
    margin-left: -12;
  }
  
  .celula{
    width: 25%;
  }

  .div-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  .spinner {
    margin-top: 30px;
    width: 50px;
    height: 50px;
  }
  
.borda-cards-eventos{
    box-shadow: rgb(163, 163, 163)  3px 3px 15px  !important;
}

.card-img-top {
    height: 200px; /* Adjust as needed */
    object-fit: cover;
}

.card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.card-text:last-child {
    margin-top: auto;
}

.borda-cards-eventos{
    box-shadow: rgb(163, 163, 163)  3px 3px 15px  !important;
}
.margin-left-5{
    margin-left: 5px;
}
.nav-texto-branco * {
    color: white !important;
    
}

.nav-texto-branco {
    background-color: black;
}
.principal{
    height: 100vh;
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.fundo-preto{
    background-color: rgb(0, 0, 0) !important;
}
.remover{
    height: 500px !important;
}
.borda-cards-noticias{
    box-shadow: rgb(163, 163, 163)  3px 3px 15px  !important;
    background-color: white !important;
}

.titulo-noticia{
    font-size: 100pt !important;
}

.cor-botao{
    background-color: rgb(0, 167, 224);
}

.padding-botao{
    padding-right: 10%;
}

.cor-botao:hover{
    background-color: rgb(0, 154, 206) !important;
}
.div-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 9999;
}
  
.spinner {
    width: 50px;
    height: 50px;
}
.title-about-us {
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

.text-about-us {
    color: oldlace;
    font-weight: 400;
    text-shadow: 2px 2px 3px #000000;
    position: relative;
}
.formulario-contato{
    background-color: rgba(255,255,255,.65);
    border-radius: 10px;
}

.cor-botao{
    background-color: rgb(0, 167, 224);
}

.cor-botao:hover{
    background-color: rgb(0, 154, 206) !important;
}
