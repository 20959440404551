.formulario-contato{
    background-color: rgba(255,255,255,.65);
    border-radius: 10px;
}

.cor-botao{
    background-color: rgb(0, 167, 224);
}

.cor-botao:hover{
    background-color: rgb(0, 154, 206) !important;
}