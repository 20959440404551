.root {
    width: 100%;
    height: 100%;
  }
  
  .mockProgressBar {
    height: 2px;
  }
  
  .table {
    width: 100%;
    position: relative;
    table-layout: fixed;
  }
  
  .tableLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 999;
  }
  
  .divButton {
    margin-bottom: 20px;
  }
  
  .wrapper {
    position: relative;
  }
  
  .buttonProgress {
    color: blue;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12;
    margin-left: -12;
  }
  
  .celula{
    width: 25%;
  }

  .div-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  .spinner {
    margin-top: 30px;
    width: 50px;
    height: 50px;
  }
  